<template>
  <v-container>
    <h3>{{ $t("message.create-item-title") }}</h3>
    <v-card flat>
      <v-col class="pr-2 pb-2" cols="12">
        <FormGroup @on-submit="createItem"/>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import FormGroup from '../components/FormGroup.vue'
export default {
  name: "CreateItem",
  components: {
    FormGroup
  },
  data(){
    return {}
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  computed: {},
  methods: {
    createItem(itemForm){
      this.$store.dispatch("itemList_module/addItemList", { itemForm })
    },
  },
};
</script>

<style>
.v-text-field .v-input__control .v-input__slot {
  min-height: 20px !important;
  display: flex !important;
  align-items: center !important;
}
.file-input-class .v-input__prepend-outer {
  margin-top: 0px !important;
}
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
</style>
